<template>
  <main class="page-projects page-update-project-module-visea p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.show' }">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'projects.visea.graph' }" aria-current="page">Modulo ViSEA (Grafo)</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.tutorial' }">Tutorial</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container-fluid p-0 overflow-hidden d-flex flex-row align-content-stretch align-items-stretch" style="border-top: 1px solid black">
      <div ref='page' class="flex-grow-1 d-flex align-content-stretch align-items-stretch overflow-hidden">
        <div id='graph-container' ref='graph-container' class="w-100"></div>
      </div>
      <div style="border-left: 1px solid black; min-width: 250px" class="pt-3 d-flex flex-column" v-if="days">
        <h4 class="px-3">Ordine di Apparizione</h4>

        <div class="overflow-auto flex-grow-1 px-3">
          <div v-for="(sequencePass, index) in sequence" :key="index" style="border: 1px solid black;" class="pt-3 px-3 mb-3">
            <h3 class="h5">Gruppo {{ index + 1 }}</h3>
            <div v-for="(day, indexDay) in sequencePass" :key="day" style="border: 1px solid black;" class="p-3 mb-3 d-flex flex-row align-items-center justify-content-between">
              <p class="mb-0">Giorno {{ day }}</p>
              <button class="btn btn-outline-danger" @click.prevent="removeDay(day, index, indexDay)">-</button>
            </div>

            <button v-if="sequencePass && sequencePass.length === 0 && sequence.length > 1" class="btn btn-outline-danger w-100 mb-3" @click.prevent="removeGroup(index)">Rimuovi</button>
          </div>
        </div>

        <div style="border-top: 1px solid black" class="p-3">
          <button class="btn btn-secondary w-100" @click.prevent="addGroup" :disabled="!sequence || (0 === sequence.length) || sequence[sequence.length - 1].length === 0">Nuovo gruppo</button>
        </div>
      </div>
    </div>
    <div class="d-flex p-3 justify-content-center bg-white" style="z-index: 1; border-top: 1px solid black">
        <template v-if="true">
          <button class="btn btn-primary mx-2" @click.prevent="GraphFit">Centra</button>
          <button class="btn btn-primary mx-2" @click.prevent="GraphDfs">Analizza</button>
          <button class="btn btn-primary mx-2" @click.prevent="daysGraph">
            <template v-if="days">Grafo Poligoni</template>
            <template v-else>
              Grafo Giornate
            </template>
            </button>
          <button class="btn btn-success mx-2" @click.prevent="saveEditorData">Salva</button>
        </template>
      </div>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api';
import validateMixin from '@/libs/Form/mixins/validate';
import ViseaGraph from '@/libs/Editor/viseagraphs';
export default {
  mixins: [validateMixin],
  components: {
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      days: false,
      feedback: null,
      projectData: {
        visea_module: null,
      },
      sequence: [[]],
    };
  },
  mounted () {
    this.$nextTick(() => {
      // Todo: make it works without reload
      this.$refs['graph-container'].width = this.$refs.page.clientWidth;
      this.$refs['graph-container'].height = this.$refs.page.clientHeight;
      if (!window.vg) {
        window.vg = new ViseaGraph('graph-container');
      }
      window.vg.setCanvas(document.getElementById('graph-container'));
      const viseaData = JSON.parse(this.projectData.visea_module);
      // console.log(viseaData.overlays);
      window.vg.importSession(viseaData.graph);
      window.vg.loadPolygon(viseaData.overlays);
      window.vg.pushSequence = (day) => {
        if (window.vg.sequence[day] === undefined) {
          this.addDayToLastGroup(day);
          window.vg.sequence[day] = this.sequence.length - 1;
        }
        window.vg.tippy_instance.hide();
      };
      this.sequence = window.vg.exportSequence();
    });
  },
  destroyed () {
    // window.vg.destroy();
  },
  methods: {
    addGroup () {
      this.sequence.push([]);
    },
    removeGroup (index) {
      this.sequence.splice(index, 1);
    },
    removeDay (day, indexGroup, indexDay) {
      this.sequence[indexGroup].splice(indexDay, 1);
      delete window.vg.sequence[day];
    },
    addDayToLastGroup (day) {
      this.sequence[this.sequence.length - 1].push(day);
    },
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.feedback = null;
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      return !this.hasErrors;
    },
    GraphFit () {
      window.vg.cy.fit();
    },
    GraphDfs () {
      window.vg.dfs();
    },
    daysGraph () {
      if (!this.days) {
        window.vg.exportAll();
        const days = window.vg.exportDaysGraph();
        window.vg.loadPolygon(days, true);
      } else {
        window.vg.loadPolygon(window.vg._polygons);
      }
      this.days = window.vg.days;
    },
    saveEditorData () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }
      // const viseaData = JSON.parse(this.projectData.visea_module);
      // viseaData.overlays = window.vg._polygons;
      // this.projectData = JSON.stringify(viseaData);
      const viseaData = JSON.parse(this.projectData.visea_module);
      viseaData.overlays = window.vg._polygons;
      viseaData.graph = window.vg.exportSession();
      this.projectData.visea_module = JSON.stringify(viseaData);
      this.$api.updateProjectModuleVisea(this.project.id, this.projectData)
        .then(() => {
          this.$router.push({ name: 'projects.show' });
        })
        .catch(err => {
          this.$log.error(err);
          this.feedback = false;
        })
      ;
    },
  },
  beforeRouteEnter (to, from, next) {
    const id = to.params?.id;
    if (!id) {
      return next(from);
    }

    $api.fetchProjectViseaModule(id)
      .then(res => res.data)
      .then(data => {
        if (!data) {
          return next(from);
        }

        next(vm => {
          vm.projectData = data;
        });
      })
      .catch(() => next(from))
    ;
  },
};

</script>
